import { createRouter, createWebHistory } from "vue-router";

//import authService from "../../plugins/auth";
import MainPage from "../views/Main.vue";
import DrumPage from "../views/Drum.vue";
import CompanyPage from "../views/Company.vue";
import GamePage from "../views/Game.vue";
import ShareScreen from "../views/ShareScreen";
import PrizePage from "../views/Prize.vue";
import EndPage from "../views/End.vue";
import BVIoffice from "@/views/BVI/BVIoffice.vue";
import BVIworkplace from "@/views/BVI/BVIworkplace.vue";
import BVIcompany from "@/views/BVI/BVIcompany.vue";
import BVIprize from "@/views/BVI/BVIprize.vue";
import BVImain from "@/views/BVI/BVImain.vue";

const routes = [
    {
        path: "/",
        name: "MainPage",
        component: MainPage,
    },
    {
        path: "/drum",
        name: "DrumPage",
        component: DrumPage,
    },
    {
        path: "/share",
        name: "ShareScreen",
        component: ShareScreen,
    },
    {
        path: "/company",
        name: "CompanyPage",
        component: CompanyPage,
    },
    {
        path: "/playing",
        name: "GamePage",
        component: GamePage,
    },
    {
        path: "/prize",
        name: "PrizePage",
        component: PrizePage,
    },
    {
        path: "/end",
        name: "EndPage",
        component: EndPage,
    },
    {
        path: "/bvi",
        name: "BVImain",
        component: BVImain,
    },
    {
        path: "/bvi/playing",
        name: "BVIoffice",
        component: BVIoffice,
    },
    {
        path: "/bvi/company",
        name: "BVIcompany",
        component: BVIcompany,
    },
    {
        path: "/bvi/workplace",
        name: "BVIworkplace",
        component: BVIworkplace,
    },
    {
        path: "/bvi/prize",
        name: "BVIprize",
        component: BVIprize,
    },
];

const router = createRouter({
    history: createWebHistory('/', { parseQuery: true }),
    routes,
});

// if (process.env.NODE_ENV == 'production') {
//     router.beforeEach(async (to, from,  next) => {
//         // Here we handle the login redirect and than send the user to the "/" route.
//         if (to.path === '/drum' || to.path === '/drum/') {
//             try {
//                 await authService.handleLoginRedirect()
//                 next()
//             } catch (error) {
//                 console.error('Ошибка при авторизации пользователя:', error);
//                 next({ name: 'MainPage' });
//             }
//         } else if (to.path !== '/' && to.path !== '/share' && to.path !== '/share/') {
//             // Inform the authentication service that a user logged in. Afterwards we send the user to the main page
//             try {
//                 const isLoggedIn = await authService.isUserLoggedIn();
//                 if (isLoggedIn) {
//                     // Если пользователь залогинен, продолжаем навигацию
//                     next();
//                 } else {
//                     console.error('Ошибка при авторизации пользователя');
//                     // Если пользователь не залогинен, редиректим на главную страницу
//                     next({ name: 'MainPage' });
//                 }
//             } catch (error) {
//                 // Обрабатываем ошибку запроса
//                 console.error('Ошибка при авторизации пользователя:', error);
//                 // Редиректим на страницу ошибки, или можно сделать другую обработку ошибки
//                 next({ name: 'MainPage' });
//             }
//         } else {
//             next()
//         }
//
//     })
// }


export default router;
