import {Scene} from 'phaser';
import store from "@/store";
import AssetsLoader from "@/game/utils/AssetsLoader";
import UI from "@/game/utils/UI";

import timer from "@/game/assets/CoffeGame/timer.png";
import background from "@/game/assets/CoffeGame/coffee_machine.png";
import arrow from "@/game/assets/CoffeGame/arrow.png";
import bars from "@/game/assets/CoffeGame/bars.png";
import red_light from "@/game/assets/CoffeGame/red_light.png"
import green_light from "@/game/assets/CoffeGame/green_light.png"
import text_0 from "@/game/assets/CoffeGame/text_0.png"
import text_1 from "@/game/assets/CoffeGame/text_1.png"
import text_2 from "@/game/assets/CoffeGame/text_2.png"
import text_3 from "@/game/assets/CoffeGame/text_3.png"
import text_4 from "@/game/assets/CoffeGame/text_4.png"
import text_5 from "@/game/assets/CoffeGame/text_5.png"

export default class CoffeeGameScene extends Scene {
    constructor() {
        super({key: 'CoffeeGameScene'});
        this.inProcess = true;
        this.speed = 1;
        this.assetsLoader = new AssetsLoader(this);
        this.sceneUI = new UI(this);
    }

    preload() {
        this.load.image('timer', timer);
        this.load.image('background', background);
        this.load.image('arrow', arrow);
        this.load.image('bars', bars);
        this.load.spritesheet('red_light', red_light, {
            frameWidth: 250,
            frameHeight: 245
        })
        this.load.spritesheet('green_light', green_light, {
            frameWidth: 250,
            frameHeight: 245
        })

        this.load.image('text_0', text_0);
        this.load.image('text_1', text_1);
        this.load.image('text_2', text_2);
        this.load.image('text_3', text_3);
        this.load.image('text_4', text_4);
        this.load.image('text_5', text_5);
    }

    create() {
        this.add.image(0, 0, 'background').setOrigin(0, 0);

        this.timer = this.add.sprite(this.scale.width/2, this.scale.height/2, "timer")
        this.add.sprite(this.scale.width/2, this.scale.height/2, "bars")
        this.arrow = this.add.sprite(this.scale.width/2, this.scale.height/2, "arrow")

        this.red_light = this.add.sprite( 1590,352,'red_light');

        this.anims.create({
            key: "red_on",
            frames: [{
                key: 'red_light',
                frame: 1
            }],
            repeat: -1
        })

        this.anims.create({
            key: "red_off",
            frames: [{
                key: 'red_light',
                frame: 0
            }],
            repeat: -1
        })

        this.green_light = this.add.sprite( 1590,this.scale.height-352,'green_light');

        this.anims.create({
            key: "green_on",
            frames: [{
                key: 'green_light',
                frame: 1
            }],
            repeat: -1
        })

        this.anims.create({
            key: "green_off",
            frames: [{
                key: 'green_light',
                frame: 0
            }],
            repeat: -1
        })

        this.timer.setOrigin(0.5, 0.5);
        this.arrow.setOrigin(1, 0.5);

        this.toStart();

        this.texts = [];
        for (let i = 0; i < 6; i++) {
            const textSprite = this.add.sprite(115, 310, `text_${i}`).setOrigin(0, 0);
            textSprite.setAlpha(0);
            this.texts.push(textSprite);
        }
        this.texts[0].setAlpha(1);

        this.input.on('pointerdown', ()=>{
            if (this.inProcess) {
                this.stopArrow();
            }
        }, this);
    }

    toStart() {
        if (this.speed === 6) {
            store.commit('setCurrentActivity', 'dialogCoffeeFinish');
            this.scene.start('DialogScene');
        }
        this.setTimerAngle();
        this.setArrowAngle();
        this.green_light.anims.play('green_off');
        this.red_light.anims.play('red_off');
    }

    setTimerAngle() {
        this.timer.angle = Math.random() * 360;
    }

    setArrowAngle() {
        const angle = Math.random() * 360;
        this.arrow.angle = angle;
    }

    moveArrow() {
         if (this.arrow.angle < 360) {
             this.arrow.angle += 1 + this.speed;
         } else {
             this.arrow.angle = 0;
         }
    }

    anglesDifference() {
        const difference = Math.abs(this.timer.angle - this.arrow.angle);
        if (difference > 180) {
            return 360 - difference;
        } else {
            return difference;
        }
    }

    stopArrow() {
        this.inProcess = false;
        if (this.anglesDifference() > 14) {
            this.fail()
        } else {
            this.gotcha()
        }
        setTimeout(()=>{
            this.inProcess = true;
            this.toStart()
        }, 500)
    }

    gotcha() {
        this.green_light.anims.play('green_on');
        this.texts[this.speed-1].setAlpha(0);
        this.texts[this.speed].setAlpha(1);
        this.speed += 1;
    }

    fail() {
        this.green_light.anims.play('red_on');
    }

    update() {
        if (this.inProcess) {
            this.moveArrow();
        }

    }
}