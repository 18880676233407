import store from "@/store";

class Dialog {
    constructor(scene, dialog) {
        //scene это контекст this сцены из phaser
        this.scene = scene;

        //
        this.dialog = dialog;
        this.dialogScreen = this.dialog.screens[0]
        this.dialogText = this.dialogScreen.dialogText;
        this.buttons = this.dialogScreen.buttons ? this.dialogScreen.buttons : [];

        this.npcReplica = null;

        //Конфиг диалогового окна
        this.dialogBorderTop = null;
        this.dialogTexture = null
        this.dialogX = 96;
        this.dialogY = 500;
        this.dialogFont = "24px Inter, Arial";
        this.dialogColor = "#FFF";
        this.dialogWidth =  1017;
        this.dialogPaddingX = 60;
        this.dialogPaddingY = 60;
        this.dialogWordWrap = { width: this.dialogWidth - this.dialogPaddingX * 2, useAdvancedWrap: true };

        this.dialogBackgroundColor = 'transparent';
        this.dialogHeight = 0;

        //Конфиг кнопок
        this.buttonFont = "24px Inter, Arial";
        this.buttonsSpacing = 20;
        this.buttonsInRow = 2
        this.buttonWidth = (this.dialogWidth / this.buttonsInRow) - (this.buttonsSpacing / this.buttonsInRow)
        this.buttonStartX = this.dialogX;
        this.buttonStartY = 0;

        //Активные кнопки, сюда пушатся зарендеренные кнопки, для удаления
        this.activeButtons = [];

        this.actions = {
            setScreen: this.setScreenAction()
        }
    }

    createDialog() {
        this.createDialogWindow();
        this.createButtons();
    }

    createDialogWindow() {
        //Текстура диалога
        this.dialogTexture = this.scene.add.sprite(this.dialogX, this.dialogY, 'dialog_texture').setOrigin(0)
        // Создаем текст диалога
        this.npcReplica = this.scene.add.text(
            this.dialogX,
            this.dialogY,
            this.dialogText,
            {
                font: this.dialogFont,
                fill: this.dialogColor,
                wordWrap: this.dialogWordWrap,
                fixedWidth: this.dialogWidth,
                padding: {
                    x: this.dialogPaddingX,
                    y: this.dialogPaddingY
                },
                backgroundColor: this.dialogBackgroundColor,
                backgroundImage: 'dialog_texture'
            }
        );



        this.updateHeights(this.npcReplica)
    }

    createButtons() {
        this.resetButtons();

        if (this.buttons.length === 0) {
            this.buttons.push({
                text: 'Пойти в опенспейс',
                action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: false}}
            })
        }

        let currentX = this.buttonStartX;
        let currentY = this.buttonStartY;

        this.buttons.forEach((button, i) => {

            let text = button.text;

            // Включить если надо посмотреть очки на кнопках
            // if (button.setStatuses) {
            //     button.setStatuses.forEach(status=>{
            //         text += ` (${status.status} ${status.count})`;
            //     })
            // }

            const buttonTexture = this.scene.add.sprite(currentX, currentY, 'button').setOrigin(0)
            const buttonHoverTexture = this.scene.add.sprite(currentX, currentY, 'button_hover').setOrigin(0).setAlpha(0)

            const dialogButton = this.scene.add.text(
                currentX,
                currentY,
                text,
                {
                    font: this.buttonFont,
                    align: 'center',
                    color: this.dialogColor,
                    backgroundColor: this.dialogBackgroundColor,
                    padding: { x: 20, y: 25 },
                    fixedWidth: this.buttonWidth
                }
            ).setDepth(3);

            buttonTexture.displayWidth = this.buttonWidth
            buttonTexture.displayHeight = dialogButton.height
            buttonHoverTexture.displayWidth = this.buttonWidth
            buttonHoverTexture.displayHeight = dialogButton.height

            // Устанавливаем интерактивность для кнопки
            dialogButton.setInteractive({ cursor: 'pointer' });

            //Увеличиваем текущие координаты для следующей кнопки
            currentX += this.buttonWidth + this.buttonsSpacing

            if ((i + 1) % this.buttonsInRow === 0) {
                currentX = this.buttonStartX;
                currentY += dialogButton.height + this.buttonsSpacing;
            }

            this.activeButtons.push([dialogButton, buttonTexture, buttonHoverTexture]);

            // У каждой кнопки есть action с названием метода в name
            // Передаём контекст
            const context = this;
            // Добавляем обработчик события нажатия на кнопку
            dialogButton.on('pointerdown', () => {
                this.scene.sceneUI.playSound('keyboard_click');
                const action = button.action;
                if (button.setStatuses) {
                    context.scene.statuses.updateStatuses(button.setStatuses)
                }
                if (button.achievement) {
                    context.scene.achievements.addAchievement(button.achievement);
                }
                if (action && typeof context[action.name] === 'function') {
                    context[action.name](action.arg);
                }
            });

            dialogButton.on('pointerover', () => {
                buttonTexture.setAlpha(0);
                buttonHoverTexture.setAlpha(1);
                // Дополнительные действия, которые нужно выполнить при наведении мышки на спрайт
            });
            dialogButton.on('pointerout', () => {
                buttonHoverTexture.setAlpha(0);
                buttonTexture.setAlpha(1);
                // Дополнительные действия, которые нужно выполнить при убирании мышки со спрайта
            });
        });
    }

    selectProftestAction(arg) {
        if (typeof arg  !== 'undefined') {
            store.commit('setUserOrientations', arg.orientation)
            if (typeof arg.finish === 'string') {
                this.changeDialog({dialog: arg.finish});
            } else {
                this.setScreenAction(arg.num);
            }
        }
    }

    goToCourse() {
        store.dispatch('addComplitedActivity', 'dialogProftest');
        window.open(store.getters.getUserOrientationsLink, "_blank");
        this.closeDialog({goTo: 'WorkPlaceScene', deactivate: false});
    }

    setScreenAction(arg) {
        if (typeof arg  !== 'undefined' && this.dialog.screens[arg]) {
            this.dialogScreen = this.dialog.screens[arg];
            this.dialogText = this.dialogScreen.dialogText;
            this.buttons = this.dialogScreen.buttons ? this.dialogScreen.buttons : [];
            this.updateDialog();
        }
    }

    changeDialog(arg) {
        store.commit('setCurrentActivity', arg.dialog);
        console.log(store.getters.getCurrentActivity)
        this.scene.scene.start('DialogScene');
    }

    closeDialog(arg) {
        if (arg.link) {
            window.open(arg.link, "_blank");
        }
        if (arg.deactivate) {
            store.dispatch('addComplitedActivity', store.getters.getCurrentActivity)
        }
        if (!this.scene.scale.isFullscreen) {
            if (arg.setFullScreen) {
                if (this.scene.scale.isFullscreen)
                {
                    this.scene.scale.stopFullscreen();
                }
                else
                {
                    this.scene.scale.startFullscreen();
                }
            }
        }

        this.scene.cameras.main.fadeOut(300, 0, 0, 0, (camera, progress)=>{
            if (progress === 1) {
                this.scene.scene.start(arg.goTo);
            }
        });
    }

    updateHeights(dialog) {
        this.dialogHeight = this.dialogTexture.height
        //this.dialogTexture.displayHeight = dialog.height;
        this.buttonStartY = this.dialogY + this.dialogHeight + this.buttonsSpacing
        return dialog
    }

    resetButtons() {
        if (this.activeButtons.length > 0) {
            this.activeButtons.forEach((elements)=> {
                elements.forEach(element=>{
                    element.destroy();
                })
            })
            this.activeButtons = [];
        }
    }

    updateDialog() {
        this.npcReplica.setText(this.dialogText);
        this.updateHeights(this.npcReplica);
        this.createButtons();
    }
}

export default Dialog;