const state = {
    statusNames: {
        comrades: [
            {name: "ЛЮБИТЕЛЬ\n" +
                    "АНЕКДОТОВ", maxVal: 3},
            {name: "ОЧЕРЕДНОЙ\n" +
                    "СТАЖЁР", maxVal: 6},
            {name: "ВЫУЧИЛИ\n" +
                    "ТВОЁ ИМЯ", maxVal: 9},
            {name: "С ТОБОЙ\n" +
                    "ХОТЯТ ОБЩАТЬСЯ", maxVal: 12},
            {name: "ЗВЕЗДА\n" +
                    "ВЕЧЕРИНОК", maxVal: 15},
        ],
        director: [
            {name: "ДАРТ ВЕЙДЕР", maxVal: 3},
            {name: "ИДУЩИЙ\n" +
                    "К ТЬМЕ", maxVal: 6},
            {name: "ЮНЫЙ\n" +
                    "ПАДАВАН", maxVal: 9},
            {name: "ВНИМАТЕЛЬНЫЙ\n" +
                    "УЧЕНИК", maxVal: 12},
            {name: "НАСТОЯЩИЙ\n" +
                    "ДЖЕДАЙ", maxVal: 15},
        ],
        effectiveness: [
            {name: "КАНДИДАТ\n" +
                    "НА УВОЛЬНЕНИЕ", maxVal: 3},
            {name: "ТАБУРЕТКА", maxVal: 6},
            {name: "ПОДАЮЩИЙ\n" +
                    "НАДЕЖДЫ", maxVal: 9},
            {name: "НАЧИНАЮЩИЙ\n" +
                    "ПРОФЕССИОНАЛ", maxVal: 12},
            {name: "БУДУЩИЙ\n" +
                    "ЭКСПЕРТ", maxVal: 15},
        ],
        psychological: [
            {name: "КУКУХА\n" +
                    "ОТЪЕЗЖАЕТ", maxVal: 3},
            {name: "ПРИГОРАЕТ", maxVal: 6},
            {name: "ВСЁ\n" +
                    "НОРМАЛЬНО", maxVal: 9},
            {name: "В РЕСУРСЕ", maxVal: 12},
            {name: "АМБАССАДОР\n" +
                    "СЧАСТЬЯ", maxVal: 15},
        ],
    },
}

const getters = {
    getStatusName: state => (status, count) => {
        console.log(count)
        let name = null;
        let statusArray = state.statusNames[status];

        let previousVal = 0;

        if (count > statusArray[statusArray.length - 1].maxVal) {
            return statusArray[statusArray.length - 1].name
        }

        for (let i = 0; i < statusArray.length; i++) {
            if (statusArray[i].maxVal > previousVal &&  count <= statusArray[i].maxVal) {
                name = statusArray[i].name;
                break;
            }
            previousVal = statusArray[i].maxVal;
        }

        return name;
    }
}

export default {
    state,
    getters
};