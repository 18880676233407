import axios from 'axios';
import metrika from "../../../plugins/metrika";

const apiUrl = process.env.VUE_APP_API_URL;

const state = {
    musicOn: false,
    userId: null,
    currentActivity: null,
    needGreetings: true,
    accessToken: false,
    needFinal: false,
    prize: null,
    second_prize: null,
    complitedActivities: [],
    statuses: {
        comrades: {
            name: 'Отношения с коллективом',
            count: 0
        },
        director: {
            name: 'Отношения с руководителем',
            count: 0
        },
        psychological: {
            name: 'Психологическое состояние',
            count: 0
        },
        effectiveness: {
            name: 'Личная эффективность',
            count: 0
        },
    },
    achievements: [],
    bvi_location: {
        open_space: {
            name: 'Опенспейс',
            bg: '/assets/images/OpenSpace/open_space.png',
            bubble_text: "<p>Ты стоишь в офисе, окружённый кипой бумаг и мерцанием экранов компьютеров. Вокруг тебя – суета коллег, звонки телефонов и шелест клавиатур.</p>" +
                "<br>" +
                "<p>Но сейчас перед тобой стоит выбор: куда отправиться?</p>"
        },
        kitchen: {
            name: 'Кухня',
            bg: '/assets/images/Kitchen/kitchen.png',
            bubble_text: "<p>На стенах свисают яркие бумажные гирлянды, но столы еще не накрыты — они пустые и ждут угощений. В воздухе витает аромат свежевыпеченного печенья</p>"
        }
    },
    gameActivites: [
        {text: "Планёрка", location: 'open_space', dialog: "dialogPlanningUp", scene: 'DialogScene', x: 410, y: 400,},
        {text: "Принтер", location: 'open_space', dialog: "dialogPrinter", scene: 'DialogScene', x: 860, y: 545},
        {text: "Кондиционер", location: 'open_space', dialog: "dialogConditioner", scene: 'DialogScene', x: 1420, y: 200},
        {text: "Гора бумаг", location: 'open_space', dialog: "dialogPapers", scene: 'DialogScene', x: 1445, y: 580},
        {text: "Коллеги", location: 'open_space', dialog: "dialogComrades", scene: 'DialogScene', x: 1070, y: 480,},
        {text: "Кофемашина", location: 'kitchen', dialog: "dialogCoffee", scene: 'DialogScene', x: 790, y: 400,},
        {text: "Холодильник", location: 'kitchen', dialog: "dialogRefrigerator", scene: 'DialogScene', x: 370, y: 450,},
        {text: "Ноутбук", location: 'kitchen', dialog: "dialogNotebook", scene: 'DialogScene', x: 860, y: 800,},
        {text: "Ёлка", location: 'kitchen', dialog: "dialogTree", scene: 'DialogScene', x: 1521, y: 185,},
        {text: "Анна", location: 'kitchen', dialog: "dialogAnna", scene: 'DialogScene', x: 200, y: 800,},
        {text: "Директор", location: 'kitchen', dialog: "dialogProftest", scene: 'DialogScene', x: 1000, y: 400,},
    ],
};

const mutations = {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    switchMusic(state) {
        state.musicOn = !state.musicOn;
    },
    setUserId(state, userId) {
        if (!localStorage.getItem('userId') || localStorage.getItem('userId') !== userId) {
            localStorage.setItem('userId', userId);
        }
        state.userId = userId;
    },
    loadGameStatus(state, data) {
        const statusesKeys = [];
        //Собираем ключи статусов, чтобы корректо обновить
        for (let key in state.statuses) {
            statusesKeys.push(key)
        }

        for (let key in data) {
            if (statusesKeys.includes(key)) {
                state.statuses[key].count = data[key]
            } else {
                state[key] = data[key];
            }

        }
    },
    resetComplitedActivities(state) {
        state.complitedActivities = []
    },
    setCurrentActivity(state, dialog) {
        state.currentActivity = dialog;
    },
    setNeedGreetings(state, status) {
        state.needGreetings = status;
    },
    addComplitedActivity(state, activity) {
        let complitedActivities = !state.complitedActivities ? [] : state.complitedActivities
        if (!complitedActivities.includes(activity)) {
            complitedActivities.push(activity);
            state.complitedActivities = complitedActivities;
        }
    },
    addAchievement(state, achievement) {
        if (!state.achievements.includes(achievement)) {
            state.achievements.push(achievement);
        }
    },
    updateStatus(state, args) {
        state.statuses[args.status].count += args.count;
    },
    setPrize(state, prize) {
        state.needFinal = false;
        state.prize = prize;
    },
    setSecondPrize(state) {
        state.second_prize = "Новогодний стикерпак";
    },
    setNeedFinal(state, bool) {
        state.needFinal = bool;
    },
};

const actions = {
    setUserIdAction({commit}, userId) {
        commit('setUserId', userId);
    },
    setNeedFinalAction({ commit }, bool) {
        commit("setNeedFinal", bool)
    },
    async getUserId({state, commit }) {
        try {
            const response = await axios.post(
                `${process.env.VUE_APP_AUTH_URL}connect/userinfo`,
                {},  // Пустое тело запроса
                {
                            headers: {
                                'Authorization': `Bearer ${state.accessToken}`
                            }
                        }
            );
            console.log(response.data)
            commit('setUserId', response.data.user_id);
            return response.data.user_id;
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async setNeedGreetingsAction({ state, commit }, status) {
        try {
            const response = await axios.post(
                `${apiUrl}/game_status/update`,
                {
                        token: state.accessToken,
                        user_id: state.userId,
                        needGreetings: status
                    }
            );
            commit('setNeedGreetings', status);
            return response;
        } catch(error) {
            console.error(error)
            throw error;
        }

    },
    async addComplitedActivity({state,  commit }, activity) {
        commit('addComplitedActivity', activity);
        try {
            const response = await axios.post(
                `${apiUrl}/game_status/update`,
                {
                        token: state.accessToken,
                        user_id: state.userId,
                        complitedActivities: JSON.stringify(state.complitedActivities)
                    }
            );
            return response
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async addAchievementAction({state, commit}, achievement) {
        commit('addAchievement', achievement)
        try {
            const response = await axios.post(
                `${apiUrl}/game_status/update`,
                {
                        token: state.accessToken,
                        user_id: state.userId,
                        achievements: JSON.stringify(state.achievements)
                    }
            );
            return response
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async updateStatusAction({state, commit}, args) {
        commit('updateStatus', args)
        try {
            const response = await axios.post(
                `${apiUrl}/game_status/update`,
                JSON.parse(`{ 
                    "token": "${state.accessToken}",
                    "user_id": "${state.userId}",
                    "${args.status}": ${state.statuses[args.status].count}
                }`)
            );
            return response
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async loadGameStatusAction({state,  commit }, userId) {
        try {
            const response = await axios.post(`${apiUrl}/game_status`, {
                token: state.accessToken,
                user_id: userId
            });
            const updatedState = response.data;
            commit('loadGameStatus', updatedState.data)
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async loadGameSharedStatusAction({ commit }, userId) {
        try {
            const response = await axios.post(`${apiUrl}/shared`, {
                user_id: userId
            });
            const updatedState = response.data;
            commit('loadGameStatus', updatedState.data)
        } catch(error) {
            console.error(error)
            throw error;
        }
    },
    async setPrizeAction({state, commit }) {
        try {
            const response = await axios.put(`${apiUrl}/get_prize`, {
                token: state.accessToken,
                user_id: state.userId
            });
            commit('setPrize', response.data);
            // console.log(response.data)
            return response.data;
        } catch(error) {
            console.error(error.message)
            throw error
        }
    },
    async setSecondPrizeAction({state, commit }) {
        try {
            const response = await axios.put(`${apiUrl}/get_second_prize`, {
                token: state.accessToken,
                user_id: state.userId
            });
            commit('setSecondPrize', response.data);
            // console.log(response.data)
            return response.data;
        } catch(error) {
            console.error(error.message)
            throw error
        }
    },
    async restartGameAction({state, commit }) {
        const response = await axios.post(`${apiUrl}/game_restart`, {
            token: state.accessToken,
            user_id: state.userId
        });
        metrika('game_play_more')
        const updatedState = response.data;
        commit('loadGameStatus', updatedState.data)
    }
};

const getters = {
    isBVImode: () => {
        var cookies = document.cookie;

        // Ищем куки bvi_target
        var bviCookie = cookies.split('; ').find(row => row.startsWith('bvi_target='));

        // Если куки bvi_target найдены и они не пусты
        if (bviCookie && bviCookie.split('=')[1]) {
            return true;
        }

        return false;
    },
    getMusicStatus: state => {
        return state.musicOn;
    },
    getUserId: state => {
        return state.userId;
    },
    getCurrentActivity: state => {
        return state.currentActivity;
    },
    getNeedGreetings: state => {
        return state.needGreetings;
    },
    getNeedFinal: state => {
        return state.needFinal;
    },
    getComplitedActivities: state => (location = 'open_space') => {
        const activities = !state.complitedActivities ? [] : state.gameActivites.filter((activity) => {
            return activity.location === location && state.complitedActivities.includes(activity.dialog);
        });
        return !state.complitedActivities ? [] : activities.map(activity => activity.dialog);
    },
    getGameActivites: (state) => (location = 'open_space') => {
        return state.gameActivites.filter(activity => activity.location === location);
    },
    getNotComplitedActivities: state => (location = 'open_space') => {
        const complitedActivitiesFilter = !state.complitedActivities ? [] : state.gameActivites.filter((activity) => {
            return activity.location === location && state.complitedActivities.includes(activity.dialog);
        });
        const complitedActivities = !state.complitedActivities ? [] : complitedActivitiesFilter.map(activity => activity.dialog);
        const gameActivites = state.gameActivites.filter(activity => activity.location === location);

        return complitedActivities.length === 0 ?
            gameActivites :
            gameActivites.filter(
                activity=>!complitedActivities.includes(activity.dialog)
            );
    },
    getStatuses: state => {
        return state.statuses;
    },
    getStatus: state => status => {
        return state.statuses[status].count;
    },
    getAchievements: state => {
        return state.achievements;
    },
    getPrize: state => {
        return state.prize;
    },
    getSecondPrize: state => {
        return state.second_prize;
    },
    getBVILocation: state => (location = 'open_space') => {
        if (location === 'kitchen' && state.complitedActivities.length > 9) {
            state.bvi_location[location].bg = '/assets/images/Kitchen/kitchen_dark.png'
        }
        return state.bvi_location[location]
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
