import {Scene} from 'phaser'
import store from "@/store";
import StatusesPanel from "@/game/utils/statusesPanel";
import AssetsLoader from "@/game/utils/AssetsLoader";
import UI from "@/game/utils/UI"

import kitchen from '@/game/assets/Kitchen/kitchen.png'
import kitchen_dark from '@/game/assets/Kitchen/kitchen_dark.png'
//import setupCamera from "../utils/setupCamera";

export default class OpenSpaceScene extends Scene {
    constructor() {
        super({key: 'KitchenScene'})
        this.statuses
        this.assetsLoader = new AssetsLoader(this);
        this.sceneUI = new UI(this);
    }

    preload() {
        this.assetsLoader.loadAssets()
        this.load.image('kitchen', kitchen)
        this.load.image('kitchen_dark', kitchen_dark)
    }

    create() {
        if (!store.getters.getMusicStatus) {
            let mainMelody = this.sound.add('main_melody', { loop: true, volume: 0.2 });
            mainMelody.play();
            store.commit('switchMusic');
        }

        this.statuses = new StatusesPanel(this);
        this.statuses.addStatusesUI();

        this.cameras.main.fadeIn(300, 0, 0, 0, );

        const activites = store.getters.getGameActivites('kitchen');
        const complitedActivities = store.getters.getComplitedActivities('kitchen');
        const background = complitedActivities.length < activites.length/2 ? 'kitchen' : 'kitchen_dark';

        this.add.image(0, 0, background).setOrigin(0);

        if (activites.length !== complitedActivities.length) {
            let i = complitedActivities.length;
            let pointer = this.add.sprite(activites[i].x, activites[i].y, `pointer-${i}`).setOrigin(0,0);

            // Устанавливаем интерактивность для кнопки
            pointer.setInteractive({ cursor: 'pointer' });

            this.anims.create({
                key: `blink`,
                frames: this.anims.generateFrameNumbers('pointer', {start: 0, end: 4}),
                frameRate: 6,
                repeat: -1
            })

            pointer.anims.play('blink');

            pointer.on('pointerdown', () => {
                store.commit('setCurrentActivity', activites[i].dialog);
                this.cameras.main.fadeOut(300, 0, 0, 0, (camera, progress)=>{
                    if (progress === 1) {
                        this.scene.start('DialogScene');
                    }
                });

            });
        } else {
            this.scene.start('WorkPlaceScene');
        }

        this.sceneUI.addAllUI();
    }

    render() {
        this.debug.inputInfo(32, 32);
    }
}
