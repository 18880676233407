import Phaser from 'phaser'
import store from "@/store";
import AssetsLoader from "@/game/utils/AssetsLoader";
import UI from "@/game/utils/UI"
import floor_image from "@/game/assets/RefrigeratorGame/floor.png"
import floor_shadow from "@/game/assets/RefrigeratorGame/floor_shadow.png"
import capibara from "@/game/assets/RefrigeratorGame/capibara.png"
import climb from "@/game/assets/RefrigeratorGame/climb.png"
import ladder_image from "@/game/assets/RefrigeratorGame/ladder.png"
import diamond_image from "@/game/assets/RefrigeratorGame/diamond.png"
import diamondparticle_image from "@/game/assets/RefrigeratorGame/diamondparticle.png"
import spike_image from "@/game/assets/RefrigeratorGame/spike.png"
import cloud_image from "@/game/assets/RefrigeratorGame/cloud.png"
import tap_image from "@/game/assets/RefrigeratorGame/tap.png"
import wall from "@/game/assets/RefrigeratorGame/wall.png"
import wall_shadow from "@/game/assets/RefrigeratorGame/wall_shadow.png"
import cucumber from "@/game/assets/RefrigeratorGame/cucumber.png"
import tomato from "@/game/assets/RefrigeratorGame/tomato.png"
import broccoli from "@/game/assets/RefrigeratorGame/broccoli.png"
import paprika_red from "@/game/assets/RefrigeratorGame/paprika_red.png"
import paprika_yellow from "@/game/assets/RefrigeratorGame/paprica_yellow.png"
import rotten_cucumber from "@/game/assets/RefrigeratorGame/rotten_cucumber.png"
import rotten_tomato from "@/game/assets/RefrigeratorGame/rotten_tomato.png"
import rotten_broccoli from "@/game/assets/RefrigeratorGame/rotten_broccoli.png"
import rotten_paprika_red from "@/game/assets/RefrigeratorGame/rotten_paprika_red.png"
import rotten_paprika_yellow from "@/game/assets/RefrigeratorGame/rotten_paprika_yellow.png"
import jump_sound from '@/game/assets/sound/jump.mp3'

const vegetables = [
    "cucumber",
    "tomato",
    "broccoli",
    "paprika_red",
    "paprika_yellow",
];

export default class RefrigeratorGameScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'RefrigeratorGameScene'
        });
        this.assetsLoader = new AssetsLoader(this);
        this.sceneUI = new UI(this);
        this.bgColor  = '#CFD5DF';
    }

    preload() {
        this.load.image("floor", floor_image);
        this.load.image("floor_shadow", floor_shadow);
        this.load.spritesheet('hero', capibara, {frameWidth: 164, frameHeight: 153});
        this.load.spritesheet('climb', climb, {frameWidth: 153, frameHeight: 153});
        this.load.image("ladder", ladder_image);
        this.load.image("diamond", diamond_image);
        this.load.image("diamondparticle", diamondparticle_image);
        this.load.image("spike", spike_image);
        this.load.image("cloud", cloud_image);
        this.load.image("tap", tap_image);
        this.load.image("wall", wall);
        this.load.image("wall_shadow", wall_shadow);
        this.load.image("cucumber", cucumber);
        this.load.image("tomato", tomato);
        this.load.image("broccoli", broccoli);
        this.load.image("paprika_red", paprika_red);
        this.load.image("paprika_yellow", paprika_yellow);
        this.load.image("rotten_cucumber", rotten_cucumber);
        this.load.image("rotten_tomato", rotten_tomato);
        this.load.image("rotten_broccoli", rotten_broccoli);
        this.load.image("rotten_paprika_red", rotten_paprika_red);
        this.load.image("rotten_paprika_yellow", rotten_paprika_yellow);
        this.load.audio('jump_sound', jump_sound);
    }

    create() {
        this.sounds = {
            jump: this.sound.add('jump_sound'),
        }
        this.direction = 'right';
        this.gameOptions = {
            takeToWin: 5,
            gameWidth: this.scale.width,
            floorStart: 1 / 5.28 * 5,
            floorGap: 153*3,
            platformWidth: 49,
            playerGravity: 10000,
            playerSpeed: 10,
            climbSpeed: 10,
            playerJump: 500,
            diamondRatio: 2,
            doubleSpikeRatio: 1,
            skyColor: 0xaaeaff,
            safeRadius: 300,
            localStorageName: "climbgame",
            versionNumber: "1.0"
        }
        this.taken = 0
        this.comletedLaddersHights = [];
        this.isClimbing = false;
        this.canJump = false;
        this.take = false;
        this.physics.world.setBounds(0, 0, 1930,8000000);
        this.cameras.main.setBackgroundColor(this.bgColor);
        this.defineGroups();
        this.drawLevel();
        this.cameras.main.startFollow(this.hero, true, 0, 1);
        this.scoreText = this.add.text(this.gameOptions.platformWidth+15, this.gameOptions.platformWidth, `Собрано: ${this.taken}/${this.gameOptions.takeToWin}`, {font: '32px Arial', fontSize: '32px', fill: '#000'});
        this.scoreText.setScrollFactor(0).setDepth(2);

        this.input.on('pointerdown', function () {
            if (!this.isClimbing) {
                this.handleTap();
            }
        }, this)

        this.physics.world.gravity.y = 800;
    }



    defineGroups() {
        this.floorGroup = this.physics.add.staticGroup();
        this.wallGroup = this.physics.add.staticGroup();
        this.ladderGroup = this.add.group();
        this.gameGroup = this.add.group();
        this.spikeGroup = this.add.group();
        this.diamondGroup = this.add.group();
        this.gameGroup.add(this.floorGroup);
        this.gameGroup.add(this.ladderGroup);
        this.gameGroup.add(this.wallGroup);
    }

    addHero() {
        this.hero = this.physics.add.sprite(this.scale.width / 2, this.scale.height * this.gameOptions.floorStart - 200, 'hero');
        this.gameGroup.add(this.hero)
        this.hero.setOrigin(0.5, 0)
        this.hero.setBounce(0.2);
        this.hero.setCollideWorldBounds(false);
        this.hero.setDepth(1)
        this.createHeroAnimations();

        this.hero.anims.play(this.direction, true);

        this.physics.add.collider(this.hero, this.floorGroup, ()=>{
            this.canJump = true;
            if (this.isClimbing && !this.physics.overlap(this.hero, this.ladderGroup)) {
                this.hero.anims.play(this.direction, true);
                this.isClimbing = false;
            }
        });

        this.physics.add.collider(this.hero, this.wallGroup, ()=>{
            this.changeDirection()
        }, null, this)

        this.physics.add.overlap(this.hero, this.ladderGroup, (player, ladder)=>{
            this.ladderToClimb = ladder;
            let diff  = Math.abs(ladder.x - player.x);
            if (diff < 10) {
                this.climbing(ladder);
            }
        }, null, this);

        this.physics.add.overlap(this.hero, this.diamondGroup, (player, diamond)=>{
            if (!this.take) {
                this.take = true;
                this.killDiamond(diamond);
                this.addScore();
            }
        }, null, this);

        this.physics.add.overlap(this.hero, this.spikeGroup, (player, spike)=>{
            let diffX  = Math.abs( player.x - spike.x );
            let diffY  = Math.abs( player.y - spike.y );
            console.log("diffX",diffX);
            console.log("diffY",diffY)
            if (!this.take && diffX < 118 && diffY < 154) {
                this.take = true;
                this.hero.setTint(0xff0000);
                this.killSpike(spike);
                this.scene.pause();
                setTimeout(() => {
                    this.scene.restart();
                }, 700); // Delay before restarting the scene (optional)
            }
        })
    }

    addScore() {
        this.taken++;
        this.scoreText.setText(`Собрано: ${this.taken}/${this.gameOptions.takeToWin}`);
        if (this.taken === this.gameOptions.takeToWin) {
            this.scene.pause();
            setTimeout(() => {
                store.commit('setCurrentActivity', 'dialogRefrigeratorFinish');
                console.log(store.getters.getCurrentActivity)
                this.scene.start('DialogScene');
            }, 700);
        }
    }

    createHeroAnimations() {
        this.anims.create({
            key: 'left',
            frames: this.anims.generateFrameNumbers('hero', {start: 0, end: 3}),
            frameRate: 13,
            repeat: -1
        });

        this.anims.create({
            key: 'turn',
            frames: [{key: 'hero', frame: 4}],
            frameRate: 20
        });

        this.anims.create({
            key: 'jump',
            frames: this.anims.generateFrameNumbers('hero', {start: 6, end: 7}),
            frameRate: 13,
            repeat: -1
        });

        this.anims.create({
            key: 'right',
            frames: this.anims.generateFrameNumbers('hero', {start: 5, end: 8}),
            frameRate: 13,
            repeat: -1
        });

        this.anims.create({
            key: 'climb',
            frames: this.anims.generateFrameNumbers('climb', {start: 0, end: 1}),
            frameRate: 13,
            repeat: -1
        })
    }

    changeDirection() {
        this.direction = this.hero.x > this.scale.width / 2 ? "left" : "right";
        this.hero.anims.play(this.direction, true);
    }

    climbing(ladder) {
        this.hero.anims.play('climb', true);
        this.hero.body.gravity.y = 0;
        this.hero.setVelocityY(-this.gameOptions.playerJump);
        if (!this.isClimbing) {
            this.isClimbing = true;
        }

        if (!this.comletedLaddersHights.includes(ladder.y)) {
            this.comletedLaddersHights.push(ladder.y);
            this.currentFloor ++;
            this.highestFloorY -= this.gameOptions.floorGap;
            this.addLevelElements()
            console.log(this.currentFloor)
        }
    }

    drawLevel() {
        this.currentFloor = 0;
        console.log(this.scale.height)
        this.highestFloorY = this.scale.height * this.gameOptions.floorStart;
        this.floorPool = [];
        this.wallPool = [];
        this.ladderPool = [];
        this.diamondPool = [];
        this.spikePool = [];
        this.currentFloor = 0;
        while(this.highestFloorY > - 2 * this.gameOptions.floorGap){
            this.addLevelElements()
            this.highestFloorY -= this.gameOptions.floorGap;
            this.currentFloor ++;
        }
        this.highestFloorY += this.gameOptions.floorGap;

        this.addHero();
    }

    addLevelElements() {
        this.addFloorAndWalls();
        if(this.currentFloor > 0){
            this.addLadder();
            this.addSpike();
            this.addDiamond();
        }
    }

    addFloorAndWalls(){
        let floor = null;
        let leftWall = null;
        let rightWall = null;
        if(this.floorPool.length > 0){
            floor = this.floorPool.pop();
            floor.y = this.highestFloorY;
            floor.setVisible(true);
            floor.setActive(true);
            leftWall = this.wallPool.pop();
            leftWall.y = this.highestFloorY;
            rightWall = this.wallPool.pop();
            rightWall.y = this.highestFloorY;
        }
        else{
            this.add.sprite(0, this.highestFloorY, "wall_shadow").setOrigin(0).setDepth(-1);
            leftWall = this.add.sprite(0, this.highestFloorY, "wall").setOrigin(0);
            this.wallGroup.add(leftWall);
            this.physics.add.existing(leftWall);
            leftWall.body.checkCollision.down = false;
            rightWall = this.add.sprite(this.scale.width - this.gameOptions.platformWidth, this.highestFloorY, "wall").setOrigin(0);
            this.wallGroup.add(rightWall);
            this.physics.add.existing(rightWall);
            rightWall.body.checkCollision.down = false;
            floor = this.add.sprite(this.gameOptions.platformWidth, this.highestFloorY, "floor");
            floor.setOrigin(0,0)
            this.add.sprite(this.gameOptions.platformWidth, this.highestFloorY + floor.height, "floor_shadow").setOrigin(0,0);
            this.floorGroup.add(floor);
            this.physics.add.existing(floor);
            floor.body.checkCollision.down = false;
        }
    }

    addLadder(){
        let ladderXPosition = Phaser.Math.Between(300, this.scale.width - 300);
        let ladder = null;
        if (this.ladderPool.length > 0) {
            ladder  = this.ladderPool.pop();
            ladder.x = ladderXPosition;
            ladder.y = this.highestFloorY;
            ladder.setVisible(true);
            ladder.setActive(true);
        } else {
            ladder = this.add.sprite(ladderXPosition, this.highestFloorY, "ladder");
            this.ladderGroup.add(ladder);
            ladder.setOrigin(0.5, 0);
            this.physics.add.existing(ladder, true);
            ladder.body.immovable = true;
        }
        this.safeZone = [];
        this.safeZone.length = 0;
        this.safeZone .push({
            start: ladderXPosition - this.gameOptions.safeRadius,
            end: ladderXPosition + this.gameOptions.safeRadius
        });
    }

    addDiamond(){
        let diamonds = 1;
        for (let i = 1; i <= diamonds; i++) {
            let diamondXPosition = this.findSpikePosition();
            if (diamondXPosition) {
                let diamond;
                if (this.spikePool.length > 0) {
                    diamond = this.spikePool.pop();
                    diamond.x = diamondXPosition;
                    diamond.y = this.highestFloorY - 20;
                    diamond.setVisible(true);
                    diamond.setActive(true);
                } else {
                    let vegetable = vegetables[Phaser.Math.Between(0, vegetables.length - 1)];
                    diamond = this.add.sprite(diamondXPosition, this.highestFloorY, vegetable);
                    diamond.setOrigin(0.5, 1);
                    this.physics.add.existing(diamond, true);
                    diamond.body.immovable = true;
                    this.diamondGroup.add(diamond);
                }
            }
        }
    }

    addSpike(){
        let spikes = 1;
        // if (Phaser.Math.Between(0, this.gameOptions.doubleSpikeRatio) === 0) {
        //     spikes = 2;
        // }
        for (let i = 1; i <= spikes; i++) {
            let spikeXPosition = this.findSpikePosition();
            if (spikeXPosition) {
                let spike;
                if (this.spikePool.length > 0) {
                    spike = this.spikePool.pop();
                    spike.x = spikeXPosition;
                    spike.y = this.highestFloorY - 20;
                    spike.setVisible(true);
                    spike.setActive(true);
                } else {
                    let vegetable = vegetables[Phaser.Math.Between(0, vegetables.length - 1)];
                    spike = this.add.sprite(spikeXPosition, this.highestFloorY, `rotten_${vegetable}`);
                    spike.setOrigin(0.5, 1);
                    this.physics.add.existing(spike, true);
                    spike.body.immovable = true;
                    this.spikeGroup.add(spike);
                }
            }
        }
    }

    findSpikePosition(){
        let attempts = 0;
        let posX;
        do {
            attempts++;
            posX = Phaser.Math.Between(150, this.sys.canvas.width - 150);
        } while (!this.isSafe(posX) && attempts < 10);
        if (this.isSafe(posX)) {
            this.safeZone.push({
                start: posX - this.gameOptions.safeRadius,
                end: posX + this.gameOptions.safeRadius
            });
            return posX;
        }
        return false;
    }

    isSafe(n) {
        for(var i = 0; i < this.safeZone.length; i++){
            if(n > this.safeZone[i].start && n < this.safeZone[i].end){
                return false;
            }
        }
        return true;
    }

    handleTap(){
        if (this.canJump) {
            this.canJump = false;
            this.sounds.jump.play({volume: 0.2})
            this.hero.setVelocityY(-this.gameOptions.playerJump);
        }
    }

    killDiamond(diamond){
        diamond.setVisible(false);
        diamond.setActive(false);
        this.diamondGroup.remove(diamond);
        this.take = false;
        this.diamondPool.push(diamond);
    }

    killSpike(spike){
        spike.setVisible(false);
        spike.setActive(false);
        this.spikeGroup.remove(spike);
        this.take = false;
        this.spikePool.push(spike);
    }

    update() {
        // Move hero
        if (!this.isClimbing) {
            this.hero.x += this.direction === 'left' ? -this.gameOptions.playerSpeed : this.gameOptions.playerSpeed;
        }
    }
}